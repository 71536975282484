import * as i0 from '@angular/core';
import { inject, Injectable, NgModule, InjectionToken } from '@angular/core';
import { CoreModule } from '@corelabs/angular-core';
import { CommonModule } from '@angular/common';
import * as i1 from '@ngrx/store';
import { createActionGroup, props, emptyProps, createReducer, on, StoreModule, createFeatureSelector, createSelector, Store } from '@ngrx/store';
import { Subject, mergeMap, map, catchError, of, tap, filter as filter$1 } from 'rxjs';
import * as i2 from '@ngrx/effects';
import { Actions, createEffect, ofType, EffectsModule } from '@ngrx/effects';
import { HttpClient } from '@angular/common/http';
import { filter, take, tap as tap$1 } from 'rxjs/operators';
const ACCOUNT_FEATURE_KEY = 'account';

/**
 * Account Actions
 */
const accountActions = createActionGroup({
  source: 'Account',
  events: {
    Register: props(),
    'Register Success': emptyProps(),
    'Register Error': props(),
    Login: props(),
    'Login Success': props(),
    'Login Error': props(),
    Logout: emptyProps(),
    'Logout Success': emptyProps(),
    'Logout Error': emptyProps(),
    'Request Reset Password': props(),
    'Request Reset Password Success': emptyProps(),
    'Request Reset Password Error': props(),
    'Reset Password': props(),
    'Reset Password Success': emptyProps(),
    'Reset Password Error': props(),
    // #TODO: Activate
    Activate: props(),
    'Activate Success': emptyProps(),
    'Activate Error': props(),
    // #TODO: Resend Activate Link
    'Resend Activate Link': props(),
    'Resend Activate Link Success': emptyProps(),
    'Resend Activate Link Error': emptyProps(),
    Clear: emptyProps()
  }
});
const initialState = {
  saving: false,
  registered: null,
  loggedIn: null,
  loggedOut: null,
  requestedResetPassword: null,
  resetPassword: null,
  activated: null,
  error: null
};
const accountReducer = createReducer(initialState,
// register
on(accountActions.register, () => ({
  ...initialState,
  saving: true,
  registered: null,
  error: null
})), on(accountActions.registerSuccess, state => ({
  ...state,
  saving: false,
  registered: true
})), on(accountActions.registerError, (state, {
  error
}) => ({
  ...state,
  saving: false,
  registered: false,
  error
})),
// login
on(accountActions.login, () => ({
  ...initialState,
  saving: true,
  loggedIn: null,
  error: null
})), on(accountActions.loginSuccess, state => ({
  ...state,
  saving: false,
  loggedIn: true
})), on(accountActions.loginError, (state, {
  error
}) => ({
  ...state,
  saving: false,
  loggedIn: false,
  error
})),
// logout
on(accountActions.logout, () => ({
  ...initialState,
  saving: true,
  error: null
})), on(accountActions.logoutSuccess, state => ({
  ...state,
  saving: false,
  loggedOut: true
})), on(accountActions.logoutError, state => ({
  ...state,
  saving: false,
  loggedOut: false
})),
// request reset password
on(accountActions.requestResetPassword, () => ({
  ...initialState,
  saving: true,
  requestedResetPassword: false,
  error: null
})), on(accountActions.requestResetPasswordSuccess, state => ({
  ...state,
  saving: false,
  requestedResetPassword: true
})), on(accountActions.requestResetPasswordError, state => ({
  ...state,
  saving: false,
  requestedResetPassword: false
})),
// reset password
on(accountActions.resetPassword, () => ({
  ...initialState,
  saving: true,
  resetPassword: false,
  error: null
})), on(accountActions.resetPasswordSuccess, state => ({
  ...state,
  saving: false,
  resetPassword: true,
  error: null
})), on(accountActions.resetPasswordError, state => {
  return {
    ...state,
    saving: false,
    resetPassword: false
  };
}),
// activate
on(accountActions.activate, () => ({
  ...initialState,
  saving: true,
  activated: null,
  error: null
})), on(accountActions.activateSuccess, state => ({
  ...state,
  saving: false,
  activated: true
})), on(accountActions.activateError, state => ({
  ...state,
  saving: false,
  activated: false
})),
// clear
on(accountActions.clear, () => ({
  ...initialState
})));
class RegisterDto {
  email;
  password;
  constructor(data) {
    const extra = data.extra;
    if (extra !== undefined) {
      Object.keys(extra).forEach(key => {
        this[key] = extra[key];
      });
    }
    this.email = data.email;
    this.password = data.password;
  }
}
class ExtraDto {}
class HttpService {
  httpClient = inject(HttpClient);
  register(value) {
    const params = new RegisterDto(value);
    return this.httpClient.skipCredentials().skipRefreshToken().post(`api/v1/auth/register`, params);
  }
  login(value) {
    const params = {
      email: value.email.trim().toLocaleLowerCase(),
      password: value.password.trim()
    };
    return this.httpClient.skipRefreshToken().addRTokenWriteInterceptor().post(`api/v1/login`, params);
  }
  logout() {
    const params = {};
    return this.httpClient.skipErrorHandler().skipRefreshToken().addRTokenWriteInterceptor().post(`api/v1/auth/logout`, params);
  }
  requestResetPassword(email) {
    const params = {
      email: email.trim().toLocaleLowerCase()
    };
    return this.httpClient.skipCredentials().skipRefreshToken().post(`api/v1/auth/password-reset`, params);
  }
  resetPassword(password, token) {
    const params = {
      password
    };
    return this.httpClient.skipCredentials().skipRefreshToken().put(`api/v1/auth/password-reset?hash=${token}`, params);
  }
  activate(token) {
    return this.httpClient.skipCredentials().skipRefreshToken().post(`api/v1/public/confirm`, {
      token
    });
  }
  resendActivationLink(email) {
    return this.httpClient.skipRefreshToken().post(`api/auth/reactivate/${email.trim().toLocaleLowerCase()}`, {});
  }
  static ɵfac = function HttpService_Factory(t) {
    return new (t || HttpService)();
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: HttpService,
    factory: HttpService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(HttpService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
const messagesSubject$ = new Subject();
class Message {
  code;
  message;
  key;
  type;
  constructor(data) {
    this.code = data.code || null;
    this.message = data.message || null;
    this.key = data.key || null;
    this.type = data.type || null;
  }
}
const message = data => {
  messagesSubject$.next(new Message(data));
};
class AccountEffects {
  actions$ = inject(Actions);
  httpService = inject(HttpService);
  // register
  register$ = createEffect(() => {
    return this.actions$.pipe(ofType(accountActions.register), mergeMap(({
      value
    }) => {
      return this.httpService.register(value).pipe(map(() => accountActions.registerSuccess()), catchError(res => of(accountActions.registerError({
        error: res.error
      }))));
    }));
  });
  registerSuccess$ = createEffect(() => {
    return this.actions$.pipe(ofType(accountActions.registerSuccess), tap(() => message({
      type: 'success',
      key: 'register-success'
    })));
  }, {
    dispatch: false
  });
  registerError$ = createEffect(() => {
    return this.actions$.pipe(ofType(accountActions.registerError), tap(() => message({
      type: 'danger',
      key: 'register-error'
    })));
  }, {
    dispatch: false
  });
  // login
  login$ = createEffect(() => {
    return this.actions$.pipe(ofType(accountActions.login), mergeMap(({
      value
    }) => {
      return this.httpService.login(value).pipe(map(data => accountActions.loginSuccess({
        data
      })), catchError(res => of(accountActions.loginError({
        error: res.error,
        email: value.email
      }))));
    }));
  });
  loginSuccess$ = createEffect(() => {
    return this.actions$.pipe(ofType(accountActions.loginSuccess), tap(() => message({
      type: 'success',
      key: 'login-success'
    })));
  }, {
    dispatch: false
  });
  loginError$ = createEffect(() => {
    return this.actions$.pipe(ofType(accountActions.loginError), tap(payload => {
      switch (payload.error.code) {
        case 401:
          message({
            code: 401,
            type: 'danger',
            key: 'login-invalid-credentials'
          });
          break;
        case 403:
          message({
            code: 403,
            type: 'danger',
            key: 'login-not-activated'
          });
          break;
      }
    }));
  }, {
    dispatch: false
  });
  // login
  logout$ = createEffect(() => {
    return this.actions$.pipe(ofType(accountActions.logout), mergeMap(() => {
      return this.httpService.logout().pipe(map(() => accountActions.logoutSuccess()), catchError(() => of(accountActions.logoutError())));
    }));
  });
  logoutSuccess$ = createEffect(() => {
    return this.actions$.pipe(ofType(accountActions.logoutSuccess), tap(() => message({
      type: 'success',
      key: 'logout-success'
    })));
  }, {
    dispatch: false
  });
  logoutError$ = createEffect(() => {
    return this.actions$.pipe(ofType(accountActions.logoutError), tap(() => message({
      type: 'danger',
      key: 'logout-error'
    })));
  }, {
    dispatch: false
  });
  // request reset password
  requestResetPassword$ = createEffect(() => {
    return this.actions$.pipe(ofType(accountActions.requestResetPassword), mergeMap(value => {
      return this.httpService.requestResetPassword(value.email).pipe(map(() => accountActions.requestResetPasswordSuccess()), catchError(errors => of(accountActions.requestResetPasswordError({
        errors
      }))));
    }));
  });
  requestResetPasswordSuccess$ = createEffect(() => {
    return this.actions$.pipe(ofType(accountActions.requestResetPasswordSuccess), tap(() => message({
      type: 'success',
      key: 'reset-password-request-success'
    })));
  }, {
    dispatch: false
  });
  requestResetPasswordError$ = createEffect(() => {
    return this.actions$.pipe(ofType(accountActions.requestResetPasswordError), tap(() => message({
      type: 'danger',
      key: 'reset-password-request-error'
    })));
  }, {
    dispatch: false
  });
  // reset password
  resetPassword$ = createEffect(() => {
    return this.actions$.pipe(ofType(accountActions.resetPassword), mergeMap(value => {
      return this.httpService.resetPassword(value.password, value.token).pipe(map(() => accountActions.resetPasswordSuccess()), catchError(response => of(accountActions.resetPasswordError({
        error: response.error
      }))));
    }));
  });
  resetPasswordSuccess$ = createEffect(() => {
    return this.actions$.pipe(ofType(accountActions.resetPasswordSuccess), tap(() => message({
      type: 'success',
      key: 'reset-password-success'
    })));
  }, {
    dispatch: false
  });
  resetPasswordError$ = createEffect(() => {
    return this.actions$.pipe(ofType(accountActions.resetPasswordError), tap(payload => {
      switch (payload.error.message) {
        case 'request.password-reset.hash.invalid':
          message({
            type: 'danger',
            key: 'reset-password-invalid-hash-error'
          });
          break;
        default:
          message({
            type: 'danger',
            key: 'reset-password-error'
          });
          break;
      }
    }));
  }, {
    dispatch: false
  });
  // activated
  activated$ = createEffect(() => {
    return this.actions$.pipe(ofType(accountActions.activate), mergeMap(value => {
      return this.httpService.activate(value.token).pipe(map(() => accountActions.activateSuccess()), catchError(errors => of(accountActions.activateError({
        errors
      }))));
    }));
  });
  activatedSuccess$ = createEffect(() => {
    return this.actions$.pipe(ofType(accountActions.activateSuccess), tap(() => message({
      type: 'success',
      key: 'activate-success'
    })));
  }, {
    dispatch: false
  });
  activatedError$ = createEffect(() => {
    return this.actions$.pipe(ofType(accountActions.activateError), tap(() => message({
      type: 'danger',
      key: 'activate-error'
    })));
  }, {
    dispatch: false
  });
  resendActivatedEmail$ = createEffect(() => {
    return this.actions$.pipe(ofType(accountActions.resendActivateLink), mergeMap(value => {
      return this.httpService.resendActivationLink(value.email).pipe(map(() => accountActions.resendActivateLinkSuccess()), catchError(() => of(accountActions.resendActivateLinkError())));
    }));
  });
  resendActivatedEmailSuccess$ = createEffect(() => {
    return this.actions$.pipe(ofType(accountActions.resendActivateLinkSuccess), tap(() => message({
      type: 'success',
      key: 'resend-activation-link-success'
    })));
  }, {
    dispatch: false
  });
  resendActivatedEmailError$ = createEffect(() => {
    return this.actions$.pipe(ofType(accountActions.resendActivateLinkError), tap(() => message({
      type: 'danger',
      key: 'resend-activation-link-error'
    })));
  }, {
    dispatch: false
  });
  static ɵfac = function AccountEffects_Factory(t) {
    return new (t || AccountEffects)();
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: AccountEffects,
    factory: AccountEffects.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AccountEffects, [{
    type: Injectable
  }], null, null);
})();
class AccountStoreModule {
  static ɵfac = function AccountStoreModule_Factory(t) {
    return new (t || AccountStoreModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: AccountStoreModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule, StoreModule.forFeature(ACCOUNT_FEATURE_KEY, accountReducer), EffectsModule.forFeature([AccountEffects])]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AccountStoreModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, StoreModule.forFeature(ACCOUNT_FEATURE_KEY, accountReducer), EffectsModule.forFeature([AccountEffects])]
    }]
  }], null, null);
})();
const alerts = {
  skip: []
};
const ENV = new InjectionToken('env.account');
class AccountModule {
  static forRoot(alerts$1 = alerts) {
    return {
      ngModule: AccountModule,
      providers: [{
        provide: ENV,
        useValue: {
          alerts: alerts$1
        }
      }]
    };
  }
  static ɵfac = function AccountModule_Factory(t) {
    return new (t || AccountModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: AccountModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CoreModule, AccountStoreModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AccountModule, [{
    type: NgModule,
    args: [{
      declarations: [],
      imports: [CoreModule, AccountStoreModule],
      exports: []
    }]
  }], null, null);
})();
const selectState = createFeatureSelector(ACCOUNT_FEATURE_KEY);
const selectRegistered = createSelector(selectState, state => {
  return state.registered;
});
const selectLoggedIn = createSelector(selectState, state => {
  return state.loggedIn;
});
const selectLoggedOut = createSelector(selectState, state => {
  return state.loggedOut;
});
const selectSaving = createSelector(selectState, state => {
  return state.saving;
});
const selectRequestedResetPassword = createSelector(selectState, state => {
  return state.requestedResetPassword;
});
const selectResetPassword = createSelector(selectState, state => {
  return state.resetPassword;
});
const selectActivated = createSelector(selectState, state => {
  return state.activated;
});
const selectError = createSelector(selectState, state => {
  return state.error;
});
class AccountFacade {
  store = inject(Store);
  registered$ = this.store.select(selectRegistered);
  loggedIn$ = this.store.select(selectLoggedIn);
  loggedOut$ = this.store.select(selectLoggedOut);
  requestedResetPassword$ = this.store.select(selectRequestedResetPassword);
  resetPassword$ = this.store.select(selectResetPassword);
  activated$ = this.store.select(selectActivated);
  saving$ = this.store.select(selectSaving);
  error$ = this.store.select(selectError).pipe(filter(data => data !== null));
  get saving() {
    let saving = false;
    this.store.select(selectSaving).pipe(take(1), tap$1(value => saving = value)).subscribe();
    return saving;
  }
  register(email, password, extra) {
    this.store.dispatch(accountActions.register({
      value: {
        email,
        password,
        extra
      }
    }));
  }
  login(email, password) {
    this.store.dispatch(accountActions.login({
      value: {
        email,
        password
      }
    }));
  }
  logout() {
    this.store.dispatch(accountActions.logout());
  }
  requestResetPassword(email) {
    this.store.dispatch(accountActions.requestResetPassword({
      email
    }));
  }
  resetPassword(password, token) {
    this.store.dispatch(accountActions.resetPassword({
      password,
      token
    }));
  }
  activate(token) {
    this.store.dispatch(accountActions.activate({
      token
    }));
  }
  clear() {
    this.store.dispatch(accountActions.clear());
  }
  static ɵfac = function AccountFacade_Factory(t) {
    return new (t || AccountFacade)();
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: AccountFacade,
    factory: AccountFacade.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AccountFacade, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class AccountMessagesService {
  env = inject(ENV);
  messages$ = messagesSubject$.pipe(filter$1(message => message.key !== null), filter$1(message => this.env.alerts.skip.includes(message.key) === false));
  static ɵfac = function AccountMessagesService_Factory(t) {
    return new (t || AccountMessagesService)();
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: AccountMessagesService,
    factory: AccountMessagesService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AccountMessagesService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();

/*
 * Public API Surface of account
 */

/**
 * Generated bundle index. Do not edit.
 */

export { AccountFacade, AccountMessagesService, AccountModule, ENV };
