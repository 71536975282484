import * as i0 from '@angular/core';
import { NgModule, Injectable, Inject, inject, Injector, InjectionToken, Optional } from '@angular/core';
import { Subject, catchError, filter, tap, share, switchMap } from 'rxjs';
import * as i1 from '@angular/common/http';
import { HttpResponse, HttpClient } from '@angular/common/http';
class CoreModule {
  static forRoot(environment) {
    return {
      ngModule: CoreModule,
      providers: [{
        provide: 'environment',
        // you can also use InjectionToken
        useValue: environment
      }]
    };
  }
  static ɵfac = function CoreModule_Factory(t) {
    return new (t || CoreModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: CoreModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CoreModule, [{
    type: NgModule,
    args: [{
      declarations: [],
      imports: [],
      exports: []
    }]
  }], null, null);
})();
const messages$ = new Subject();
class Message {
  code;
  message;
  key;
  type;
  constructor(data) {
    this.code = data.code || null;
    this.message = data.message || null;
    this.key = data.key || null;
    this.type = data.type || null;
  }
}
const message = data => {
  messages$.next(new Message(data));
};
class ApiErrorInterceptor {
  intercept(request, next) {
    return next.handle(request).pipe(catchError(error => {
      return this.errorHandler(error);
    }));
  }
  errorHandler(response) {
    this.errorCodes(response);
    throw response;
  }
  errorCodes(response) {
    switch (response.status) {
      case 401:
        // Refresh Token Interceptor handles this
        break;
      case 403:
        message({
          code: 403
        });
        // #FIXME error message
        break;
      case 404:
        message({
          code: 404,
          key: '404-not-found'
        });
        break;
      default:
        message({
          code: response.status
        });
        break;
    }
  }
  static ɵfac = function ApiErrorInterceptor_Factory(t) {
    return new (t || ApiErrorInterceptor)();
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: ApiErrorInterceptor,
    factory: ApiErrorInterceptor.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ApiErrorInterceptor, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class ApiPrefixInterceptor {
  environment;
  constructor(environment) {
    this.environment = environment;
  }
  intercept(request, next) {
    if (!/^(http|https):/i.test(request.url) && !/assets\/i18n\//i.test(request.url)) {
      request = request.clone({
        url: this.environment.serverUrl + request.url
      });
    }
    return next.handle(request);
  }
  static ɵfac = function ApiPrefixInterceptor_Factory(t) {
    return new (t || ApiPrefixInterceptor)(i0.ɵɵinject('environment'));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: ApiPrefixInterceptor,
    factory: ApiPrefixInterceptor.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ApiPrefixInterceptor, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: ['environment']
    }]
  }], null);
})();
class ApiTokenCredentialsInterceptor {
  intercept(request, next) {
    request = request.clone({
      withCredentials: true
    });
    return next.handle(request);
  }
  static ɵfac = function ApiTokenCredentialsInterceptor_Factory(t) {
    return new (t || ApiTokenCredentialsInterceptor)();
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: ApiTokenCredentialsInterceptor,
    factory: ApiTokenCredentialsInterceptor.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ApiTokenCredentialsInterceptor, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
const environment = {
  storage: {
    key: 'R-Key'
  }
};
class Token {
  static write(token) {
    if (token === null) {
      localStorage.removeItem(environment.storage.key);
    } else {
      localStorage.setItem(environment.storage.key, token);
    }
  }
  static read() {
    const token = localStorage.getItem(environment.storage.key);
    if (!token) {
      return null;
    }
    return token;
  }
}
class ApiRTokenWriteInterceptor {
  intercept(request, next) {
    return next.handle(request).pipe(filter(event => event instanceof HttpResponse), tap(event => {
      const token = event.headers.get('R-Key');
      Token.write(token);
    }));
  }
  static ɵfac = function ApiRTokenWriteInterceptor_Factory(t) {
    return new (t || ApiRTokenWriteInterceptor)();
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: ApiRTokenWriteInterceptor,
    factory: ApiRTokenWriteInterceptor.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ApiRTokenWriteInterceptor, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class ApiRTokenReadInterceptor {
  intercept(request, next) {
    const token = Token.read();
    if (token !== null) {
      request = request.clone({
        setHeaders: {
          'R-Key': token
        }
      });
    }
    return next.handle(request);
  }
  static ɵfac = function ApiRTokenReadInterceptor_Factory(t) {
    return new (t || ApiRTokenReadInterceptor)();
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: ApiRTokenReadInterceptor,
    factory: ApiRTokenReadInterceptor.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ApiRTokenReadInterceptor, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
let HttpService$1 = class HttpService {
  httpClient = inject(HttpClient);
  refresh() {
    return this.httpClient.skipRefreshToken().addRTokenReadInterceptor().addRTokenWriteInterceptor().post('api/v1/token/refresh', {}, {
      withCredentials: true
    }).pipe(share());
  }
  static ɵfac = function HttpService_Factory(t) {
    return new (t || HttpService)();
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: HttpService,
    factory: HttpService.ɵfac,
    providedIn: 'root'
  });
};
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(HttpService$1, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
const errors$ = new Subject();
const error = error => {
  errors$.next(error);
};

/**
 *  Call refrest token request on 401 error
 */
class ApiTokenRefreshInterceptor {
  injector = inject(Injector);
  inflightAuthRequest;
  authService;
  intercept(request, next) {
    this.authService = this.injector.get(HttpService$1);
    return next.handle(request).pipe(catchError(error => {
      if (error.status === 401) {
        return this.refreshTokenRequest(next, request);
      }
      throw error;
    }));
  }
  /**
   * Token Refresh process
   */
  refreshTokenRequest(next, authReq) {
    let authReqRepeat;
    return this.inflightAuth.pipe(switchMap(() => {
      this.clearInflightAuth();
      authReqRepeat = authReq.clone();
      return next.handle(authReqRepeat);
    }), catchError(error$1 => {
      // show message
      if (error$1.status === 401 && this.inflightAuthRequest) {
        // #FIXME error message - session expired
      }
      // remove inflight auth request
      this.clearInflightAuth();
      // return
      error('unauthorized');
      throw error$1;
    }));
  }
  /**
   * Get Inflight Auth
   */
  get inflightAuth() {
    if (!this.inflightAuthRequest) {
      this.inflightAuthRequest = this.authService.refresh();
    }
    return this.inflightAuthRequest;
  }
  /**
   * Clear inflightAuthRequest
   */
  clearInflightAuth() {
    this.inflightAuthRequest = null;
  }
  static ɵfac = function ApiTokenRefreshInterceptor_Factory(t) {
    return new (t || ApiTokenRefreshInterceptor)();
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: ApiTokenRefreshInterceptor,
    factory: ApiTokenRefreshInterceptor.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ApiTokenRefreshInterceptor, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();

// From @angular/common/http/src/interceptor: allows to chain interceptors
class HttpInterceptorHandler {
  next;
  interceptor;
  constructor(next, interceptor) {
    this.next = next;
    this.interceptor = interceptor;
  }
  handle(request) {
    return this.interceptor.intercept(request, this.next);
  }
}
/**
 * Allows to override default dynamic interceptors that can be disabled with the HttpService extension.
 * Except for very specific needs, you should better configure these interceptors directly in the constructor below
 * for better readability.
 *
 * For static interceptors that should always be enabled (like ApiPrefixInterceptor), use the standard
 * HTTP_INTERCEPTORS token.
 */
const HTTP_DYNAMIC_INTERCEPTORS = new InjectionToken('HTTP_DYNAMIC_INTERCEPTORS');
/**
 * Extends HttpClient with per request configuration using dynamic interceptors.
 */
class HttpService extends HttpClient {
  httpHandler;
  injector;
  interceptors;
  constructor(httpHandler, injector, interceptors = []) {
    super(httpHandler);
    this.httpHandler = httpHandler;
    this.injector = injector;
    this.interceptors = interceptors;
    if (!this.interceptors) {
      // Configure default interceptors that can be disabled here
      this.interceptors = [this.injector.get(ApiTokenCredentialsInterceptor), this.injector.get(ApiPrefixInterceptor), this.injector.get(ApiErrorInterceptor), this.injector.get(ApiTokenRefreshInterceptor)];
    }
  }
  skipErrorHandler() {
    return this.removeInterceptor(ApiErrorInterceptor);
  }
  skipCredentials() {
    return this.removeInterceptor(ApiTokenCredentialsInterceptor);
  }
  skipApiPrefix() {
    return this.removeInterceptor(ApiPrefixInterceptor);
  }
  skipRefreshToken() {
    return this.removeInterceptor(ApiTokenRefreshInterceptor);
  }
  addRTokenWriteInterceptor() {
    return this.addInterceptor(ApiRTokenWriteInterceptor);
  }
  addRTokenReadInterceptor() {
    return this.addInterceptor(ApiRTokenReadInterceptor);
  }
  // Override the original method to wire interceptors when triggering the request.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  request(method, url, options) {
    const handler = this.interceptors.reduceRight((next, interceptor) => new HttpInterceptorHandler(next, interceptor), this.httpHandler);
    return new HttpClient(handler).request(method, url, options);
  }
  // eslint-disable-next-line @typescript-eslint/ban-types
  removeInterceptor(interceptorType) {
    return new HttpService(this.httpHandler, this.injector, this.interceptors.filter(i => !(i instanceof interceptorType)));
  }
  addInterceptor(interceptorType) {
    return new HttpService(this.httpHandler, this.injector, [...this.interceptors, this.injector.get(interceptorType)]);
  }
  static ɵfac = function HttpService_Factory(t) {
    return new (t || HttpService)(i0.ɵɵinject(i1.HttpHandler), i0.ɵɵinject(i0.Injector), i0.ɵɵinject(HTTP_DYNAMIC_INTERCEPTORS, 8));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: HttpService,
    factory: HttpService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(HttpService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpHandler
  }, {
    type: i0.Injector
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [HTTP_DYNAMIC_INTERCEPTORS]
    }]
  }], null);
})();

/*
 * Public API Surface of core
 */

/**
 * Generated bundle index. Do not edit.
 */

export { ApiErrorInterceptor, ApiPrefixInterceptor, ApiRTokenReadInterceptor, ApiRTokenWriteInterceptor, ApiTokenCredentialsInterceptor, ApiTokenRefreshInterceptor, CoreModule, HTTP_DYNAMIC_INTERCEPTORS, HttpService, errors$, messages$ };
